import { Select } from "react-daisyui";

export default function SelectDropdownComponent({ options, currValue, labelName, onChangeHandler, disabled = false }) {
  return (
    <div>
      <Select
        value={currValue}
        onChange={(event) => onChangeHandler(event.target.value)}
        className={`${currValue === 0 ? "bg-gray-300" : ""}`}
      >
        <Select.Option value="0">{labelName}</Select.Option>
        {options && options.map(option => (
          <Select.Option value={option.id} key={`option_${option.id}`}>{option.name}</Select.Option>
        ))}
      </Select>
    </div>

  )
}